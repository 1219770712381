<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>카지노&Slot</sub-title>
<!--                <div class="main_panel" style="text-align: center">-->
<!--                    <p style="margin: 10px 0;color: #ffa500;font-size: 15px">* 정규API 구입후 사용가능합니다</p>-->
<!--                    <p><a href="http://tgame365.com/" target="_blank">http://tgame365.com</a></p>-->
<!--                </div>-->

                <div class="cs_panel_button">
                    <button class="btn-casino" :class="{'btn-casino-active':type===1}"
                            @click="change(1)">카지노
                    </button>
                    <button class="btn-slot" :class="{'btn-casino-active':type===2}"
                            @click="change(2)">슬롯
                    </button>
<!--                    <button class="btn-slot" :class="{'btn-casino-active':type===3}"-->
<!--                            @click="change(3)">피쉬게임-->
<!--                    </button>-->
                </div>
                <div class="casino_panel" v-if="type===1">
                    <div class="casino">
                        <div @click="openTgame365CasinoGame(leisureConst.EVOLUTION)">
                            <img src="../../assets/images/casino/renai/evo.jpg" alt="">
                        </div>
                        <div @click="openTgame365CasinoGame(leisureConst.SEXYGAMING)">
                            <img src="../../assets/images/casino/renai/sexy.jpg" alt="">
                        </div>
                        <div @click="openTgame365CasinoGame(leisureConst.AGCASINO)">
                            <img src="../../assets/images/casino/renai/ag.jpg" alt="">
                        </div>
                    </div>
                </div>


                <div class="casino_panel" v-if="type === 2">
                    <iframe id="token-gamefram-slot" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen
                            :src="iframUrlSlot"></iframe>
                </div>
                <div class="casino_panel" v-if="type === 3">
                    <iframe id="token-gamefram-fish" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen
                            :src="iframUrlFish"></iframe>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <!--위로가기-->
        <scroll-top-comp></scroll-top-comp>
        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import SubTitle from "../../components/SubTitle";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {
        addMemberCasinoCash,
        getAccountBalance, getCasinoMaintaining,
        getGameList,
        getGameUrl,
        getLobbyUrl, getT365CasionLobbyUrl, subjectMemberCasinoCash
    } from "../../network/casinoRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {
        RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID,
        RECEIVE_HIDE_LOADING,
        RECEIVE_SHOW_LOADING
    } from "../../store/mutation-types";
    import {postionMixin, tgame365Mixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import leisureConst from "../../common/leisureConst";
    import {getTgame365Hash} from "../../network/userRequest";

    export default {
        name: "Tgame365CasinoSlotGame",
        mixins: [postionMixin,tgame365Mixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                sportsConst,
                leisureConst:leisureConst,
                position: "Casino",
                searchVal: '',
                type: 1,
                kind: leisureConst.LEISURE_KIND_TGAME365_CASINO,
                iframUrlSlot:'',
                iframUrlFish:'',
            }
        },
        methods: {
            openTgame365CasinoGame(gtype){
                getT365CasionLobbyUrl(gtype).then(res=>{
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            change(type) {
                this.type = type
                if(type===2){
                    getTgame365Hash().then(res => {
                        console.log(res)
                        let uid = res.data.uid;
                        let hash = res.data.hash;
                        this.iframUrlSlot = 'http://api.tgame365.com/api/?gtype=slot&uid=' + uid + '&hash=' + hash
                    })
                }
                if(type===3){
                    getTgame365Hash().then(res => {
                        console.log(res)
                        let uid = res.data.uid;
                        let hash = res.data.hash;
                        this.iframUrlFish = 'http://api.tgame365.com/api/?gtype=fish&uid=' + uid + '&hash=' + hash
                    })
                }
            }
        },
        created() {
            if (this.$store.state.isLogin) {
                getCasinoMaintaining().then(res => {
                    if (res.data.success) {
                        if (!res.data.data) {
                            this.$swal({
                                title: '카지노 점검중입니다',
                                type: 'error',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        }
                    }
                })


                this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_TOKENGAME);
                this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    #token-gamefram-slot {
        width: 100%;
        height: 720px!important;
    }
    #token-gamefram-fish {
        width: 100%;
        height: 620px;
    }
    @media screen and (max-width: 1024px) {
        #token-gamefram-slot{
            height: 1620px!important;
        }
        #token-gamefram-fish{
            height: 350px!important;
        }
    }


    .casino_panel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16px;
    }


    .t2 input {
        width: 100%;
        text-align: center;
        background-color: #ffffff;
        height: 28px;
        color: #0b1423;
    }
    .cs_panel_button{
        margin: 20px 0 0 0 ;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-casino{
        border: 2px solid var(--loginBtnBg);
        border-radius: 3px;
        padding: 10px 0;
        width: 50%;
        margin: 0 5px;
    }
    .btn-casino-active{
        background-color: var(--loginBtnBg);
        color: #000000;
        border-radius: 3px;
        padding: 10px 0;
        width: 50%;
        margin: 0 5px;
        font-weight: bold;
    }
    .btn-slot{
        border: 2px solid var(--loginBtnBg);
        border-radius: 3px;
        padding: 10px 0;
        width: 50%;
        margin: 0 5px;

    }


    .button_group a {
        margin-top: 3px;
    }

    .casino_panel .casino {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .casino_panel .casino div {
        width: 33%;
        box-sizing: border-box;
        padding: 10px 10px;
        text-align: center;
    }

    .casino_panel .casino {
        max-width: 100%;
        cursor: pointer;
    }

    .casino img {
        width: 100%;
        border: 1px solid #008400;
        border-radius: 2px;
    }

    .casino_panel .slot_game img {
        width: 100%;
        border: 1px solid #f4c600;
        border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
        .casino div {
            width: 100% !important;
            box-sizing: border-box;
            padding: 10px 10px;
        }

        .casino_panel .slot_game div {
            width: 45.5%;
        }

        .casino_panel .slot_company a {
            width: 48% !important;
        }

        .casino_panel .board_box {
            width: 100% !important;
        }

    }
</style>